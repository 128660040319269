<template>
	<div
		class="auction"
	>
		<div
			v-if="getBids"
			class="bids"
			ref="bids"
		>
			<V2BidButton
				v-for="(bid, i) of getBids"
				:key="i"
				:bid="bid"
				:selected="getSelectedBid === bid.bid"
				:isCorrect="isCorrect"
				:disabled="disabled"
				@select-bid="selectBid"
			/>
		</div>
		<div class="special-bids">
			<V2BidButton
				:bid="getPass"
				:showLongPass="true"
				:selected="getSelectedBid === getPass.bid"
				:isCorrect="isCorrect"
				:disabled="disabled"
				@select-bid="selectBid"
			/>
			<V2BidButton
				:bid="getDouble"
				:selected="getSelectedBid === getDouble.bid"
				:isCorrect="isCorrect"
				:disabled="disabled"
				@select-bid="selectBid"
			/>
			<V2BidButton
				:bid="getReDouble"
				:selected="getSelectedBid === getReDouble.bid"
				:isCorrect="isCorrect"
				:disabled="disabled"
				@select-bid="selectBid"
			/>
		</div>
	</div>
</template>

<script>

	import V2BidButton    from '@/components/v2/simulator/bidding/V2BidButton';
	import gameService  from '@/services/gameService';

	export default {
		components: {
			V2BidButton
		},
		props: {
			diagram: {
				type: Object,
				required: true
			},

			selectedBid: {
				type: Object
			},

			isConfirmed: {
				type: Boolean,
				default: false
			},

			disabled: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
		}),
		asyncComputed: {
			isCorrect () {
        if (!this.isConfirmed || !this.selectedBid?.bid) {
          return null;
        }
        return this.diagram.config.answerValue === this.selectedBid.bid;
			},

			async getGameData () {
				if (!this.getCurrentBid) {
					return this.getNewGame({ bids: '' });
				}
				try {
					if (this.errors &&
							this.errors[`currentBid-${this.diagram.uuid}`] &&
							this.errors[`currentBid-${this.diagram.uuid}`].length) {
						return this.getNewGame({ bids: '' });
					}
					return this.getNewGame({
						bids: [this.getCurrentBid]
					});
				} catch (error) {
					return this.getNewGame({ bids: '' });
				}
			},
			getCurrentBid () {
				return this.diagram.config.currentBid;
			},

			async getBids () {
        if (!(await this.getGameData)?.bidOptions?.contract) {
          return false;
        }
        return (await this.getGameData).bidOptions.contract;
			}
		},

		computed: {

			getSelectedBid () {
        if (!this.selectedBid) {
          return '';
        }
        return this.selectedBid.bid;
			},

			getPass () {
				const pass = {
					bid: 'P',
					type: 'pass'
				};

				return {
					...pass,
					isValid: true
				};
			},
			getDouble () {
				const double = {
					bid: 'X',
					type: 'double'
				};
				return {
					...double,
					isValid: true
				};

			},
			getReDouble () {
				const reDouble = {
					bid: 'XX',
					type: 'redouble'
				};

				return {
					...reDouble,
					isValid: true
				};
			}
		},

		mounted () {
			this.scrollToBottomRow();
		},

		emits: ['select-bid'],

		methods: {
			selectBid (bid) {
				this.$emit('select-bid', bid);
			},

			async getNewGame ({ bids }) {
				return gameService.getNewGameFromGameState({
					gameState: {
						name: 'Bidding table',
						deal: 'N:',
						auction: {
							value: 'N',
							bids
						}
					}
				});
			},
			scrollToRow (rowToScrollTo) {
				this.$nextTick(() => {
					if (!this.$refs.bids) {
						return false;
					}
					const bidsHeight = this.$refs.bids.scrollHeight;
					const bidHeight = bidsHeight / 7;
					const amountToScroll = rowToScrollTo * bidHeight;
					const scrollTop = bidsHeight - amountToScroll - (bidHeight * 3);
					this.$refs.bids.scrollTop = scrollTop;
				});
			},
			scrollToBottomRow () {
				if (!this.getCurrentBid ||
						this.getCurrentBid === 'P' ||
						this.getCurrentBid === 'X' ||
						this.getCurrentBid === 'XX') {
					return this.scrollToRow(1);
				}
				this.scrollToRow(this.getCurrentBid[0]);
			}
		}
	};

</script>

<style lang="scss" scoped>
  .auction {
		width: 305px;
		background-color: $c-gray-l2;
    padding: 16px 20px;
    border: 1px solid $c-gray-l;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 12px;

		.bids {
			width:100%;
			display:grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 0px 4px;
      height: 8rem;
      overflow-y: scroll;
      scrollbar-color: #999 #333;
      padding-top: 11px;
		}

		::v-deep .bid-button {
			width: 100%;
      height: 30px;
      border-radius: 5px;
      overflow: hidden;
      margin-top: -5px;
      box-shadow: 0px 2.56px 6.4px 1.92px #00000040;

			&[data-value='1'] {
				order:7;
			}
			&[data-value='2'] {
				order:6;
			}
			&[data-value='3'] {
				order:5;
			}
			&[data-value='4'] {
				order:4;
			}
			&[data-value='5'] {
				order:3;
			}
			&[data-value='6'] {
				order:2;
			}
			&[data-value='7'] {
				order:1;
			}

			.bid {
				flex-grow:1;
				margin:0;
				height:100%;
				width:100%;
				.value {
					margin-right:0.1em;
					font-size:rem(13);
					line-height:1;
					font-weight:700;
				}
				[data-component='suit-symbol'] {
					[data-component='icon'] {
						width: auto;
            height: 15px;
					}
				}
			}
		}
		.special-bids {
			width:100%;
			display:flex;
      gap: 5px;

			::v-deep .bid-button {
				&.pass {
					width:50%;
				}

				&.double {
          width: 20%
        }

				&.redouble{
					width:30%;
				}
			}
		}
	}

</style>
